import { BEMGenerator } from '@oahz/neact-utils'
import { Link, useRoute, useService } from '@oahz/neact'
import I18N from '@feature/i18n'
import { formatDateTime } from '@feature/shared'
import { Button } from '@bedrock/components'
import { MainContainer, PageContainer, PageLoading } from '@/components/page-view'
import { DataBlock, DataItem, DataPanel } from '@/components/data-view'
import { SupplierContentInfos, SupplierHeader } from '@/components/supplier'
import { bizEnums, bizService } from '@/services'
import { useSelector } from '@/store'
import './style.less'

const bem = BEMGenerator('peinch')

export default () => {
  const { query } = useRoute()
  const { supplierInfo } = useSelector(state => state.account)
  // 从档案列表中的信息变更记录过来的，Query参数会带上applicationId
  // 从供应商详情过来的，Query参数上没有applicationId，因为当前状态就是在信息变更审核中
  const applicationId = query.applicationId ? +query.applicationId : supplierInfo.applicationId!
  const { data, loading } = useService(async () =>
    bizService.supplierApplicationQuerySpAppModDetail({
      subSet: supplierInfo.subSet,
      applicationId,
      needDesensitization: true,
    })
  )

  if (!data) return loading ? <PageLoading /> : null

  return (
    <PageContainer className={bem()} htmlTitle={data.id + ''}>
      <SupplierHeader
        data={{ ...data, modifiedOaFlowStatus: data.oaFlowStatus }}
        action={
          data.displayModificationButton && (
            <Link to={`/enterprise/info-update?applicationId=${applicationId}`} target="_blank">
              <Button type="checked-neutral">{I18N.auto.xinXiBianGeng}</Button>
            </Link>
          )
        }
      />
      <MainContainer w1280 className={bem('main')}>
        <DataPanel>
          <DataBlock title={I18N.auto.bianGengXinXi}>
            <DataItem label={I18N.auto.chuangJianShiJian} value={formatDateTime(data.createdAt)} />
            <DataItem label={I18N.auto.chuangJianRen} value={data.creator?.name} />
            <DataItem label={I18N.auto.bianGengShuoMing} value={data.remark} />
            {data.oaFlowStatus === bizEnums.SpOaFlowStatusEnum.REJECTED && (
              <DataItem label="不通过原因" value={data.auditInfo?.info} />
            )}
          </DataBlock>
        </DataPanel>
        {data.current && <SupplierContentInfos data={{ ...data, ...data.current }} prevData={data.prev} />}
      </MainContainer>
    </PageContainer>
  )
}
