import { BEMGenerator, useRCComputed, useRCMount } from '@oahz/neact-utils'
import { Link, useManualService } from '@oahz/neact'
import { Edit } from '@bedrock/icons-react'
import { Button } from '@bedrock/components'
import I18N from '@feature/i18n'
import { formatDateTime } from '@feature/shared'
import { useSelector } from '@/store'
import { bizEnums, bizService } from '@/services'
import { SupplierContentInfos, SupplierHeader, pickHeaderDataFromDetailDto } from '@/components/supplier'
import { MainContainer, PageContainer, PageLoading } from '@/components/page-view'
import { DataBlock, DataItem, DataPanel } from '@/components/data-view'
import './style.less'

const bem = BEMGenerator('peionsh')

export const OnboundShowPage = () => {
  const { supplierInfo } = useSelector(state => state.account)
  const { data, loading, request } = useManualService(() =>
    bizService.supplierApplicationQuerySupplierOnboardDetail({
      subSet: supplierInfo.subSet,
      applicationId: supplierInfo.applicationId!,
      needDesensitization: true,
    })
  )

  useRCMount(request)

  const headerData = useRCComputed(() => {
    return data ? pickHeaderDataFromDetailDto({ ...data, onboardProgress: supplierInfo.onboardProgress }) : {}
  }, [data])

  if (!data) return loading ? <PageLoading /> : null

  return (
    <PageContainer className={bem()} htmlTitle={data.id + ''}>
      <SupplierHeader
        data={headerData}
        action={
          supplierInfo.onboardProgress === bizEnums.SpOnboardProgressEnum.FAIL && (
            <Link to={`/enterprise/resubmit`} target="_blank">
              <Button type="primary" icon={<Edit />}>
                {I18N.auto.chongXinZhunRu}
              </Button>
            </Link>
          )
        }
      />
      <MainContainer w1280 className={bem('main')}>
        <DataPanel>
          <DataBlock title="准入信息">
            <DataItem label="创建时间" value={formatDateTime(data.createdAt)} />
            <DataItem
              label="创建人"
              value={data.sourceType === bizEnums.SourceTypeEnum.PARTNERSHIP ? data.creator?.name : '代理注册'}
            />
            {supplierInfo.onboardProgress === bizEnums.SpOnboardProgressEnum.FAIL && (
              <DataItem label="拒绝理由" value={data.auditInfo?.info} />
            )}
          </DataBlock>
        </DataPanel>
        <SupplierContentInfos data={data} />
      </MainContainer>
    </PageContainer>
  )
}
