import { ReactNode, useCallback, useEffect, useState } from 'react'
import { BEMGenerator, useRCMount, useRCUpdate, useRCWatch } from '@oahz/neact-utils'
import { useManualService, useRoute, useRouter } from '@oahz/neact'
import {
  DataContainer,
  DataCard,
  DataGrid,
  DataItem,
  DataBlock,
  DataForm,
  DataFormItem,
  ShowCutOffTime,
  AsyncButton,
} from '@feature/views'
import { Bill, Close, Delete, Edit, GoldMedalsFilled, Save, RollTopLeft } from '@bedrock/icons-react'
import {
  Button,
  Form,
  Input,
  DatePicker,
  Select,
  Modal,
  Message,
  InputNumber,
  Tooltip,
  Popconfirm,
  Tag,
} from '@bedrock/components'
import { formatDateTime } from '@feature/shared'
import dayjs from 'dayjs'
import { forEach } from 'lodash'
import { useDispatch } from '@/store'
import { PageContainer, MainContainer, PageHeader, PageLoading } from '@/components/page-view'
import { apiService, bizEnums, type defs } from '@/services'
import { CompanyView, DataMoney, DataTaxRate, renderPeriod } from '@/components/data-view'
import {
  AmountInput,
  LicensingPeriodSelect,
  LineAnchorTitleView,
  QuotationLineEmpty,
  QuoteLaxSelect,
  QutotationAttachments,
  QutotationStatusView,
  QutotationUpload,
} from '@/components/quotation'
import { computeBidMoney } from '@/components/app-rfx'
import { HistoryLog } from '@/components/tender'
import './style.less'

const bem = BEMGenerator('pquotes')

const useTotalPrice = (data: defs.QuotationDetailDto) => {
  const unTaxBenchmark = data.moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.UNTAXED
  const [totalPrice, setTotalPrice] = useState(
    unTaxBenchmark ? data?.unTaxTotalPrice?.amount : data?.totalPrice?.amount
  )
  const [lineMoneyList, setlLineMoneyList] = useState({}) //竞价整单情况下的报价总金额
  const [listMoneyFlag, setListMoneyFlag] = useState(false) //存过一份数据，就不去改变了

  const currency = data.quotationCurrency

  const lineQuotationMoneyChange = useCallback(
    (lineId: string, amount: number) => {
      let _list = { ...lineMoneyList, [lineId]: amount }
      let _total = 0
      forEach(_list, (item: number) => {
        _total = _total + (item || 0)
      })
      const fixedCount = currency && ['JPY', 'KRW'].includes(currency) ? 0 : 2
      _total = Number(_total.toFixed(fixedCount))
      setlLineMoneyList(_list)
      setTotalPrice(_total)
    },
    [lineMoneyList, currency]
  )

  useRCWatch(() => {
    if (data?.lines && !listMoneyFlag) {
      setListMoneyFlag(true)
      let _res = {}
      data?.lines.map((item: defs.QuotationLineDto) => {
        _res[item.rfxLineId] = item.isTenderDiscarded
          ? 0
          : ((unTaxBenchmark ? item?.quotationUnTaxMoney?.amount : item?.quotationMoney?.amount) || 0) +
            (item?.orderMethod === bizEnums.PlaceOrderMethodEnum.QUANTITY ? item.freight?.amount || 0 : 0)
      })
      setlLineMoneyList(_res)
    }
  }, [data, listMoneyFlag])

  return {
    lineQuotationMoneyChange: lineQuotationMoneyChange,
    totalMoney: { amount: totalPrice, currency: currency },
  }
}

const genSubmitParams = (formVal: any) => {
  const linesFormVal = Object.fromEntries(Object.entries(formVal).filter(([key, value]) => key.includes('-')))
  let lines = []
  for (var key in linesFormVal) {
    // 从键名中获取索引和属性名
    var parts = key.split('-')
    var index = parseInt(parts[0].replace('Line', ''))
    var prop = parts[1]
    // 如果结果数组中还没有对应索引的对象，就创建一个
    if (!lines[index]) {
      lines[index] = {}
    }
    // 将值添加到对应索引的对象中
    if ('validityPeriod' === prop) {
      // 报价有效期，要求这个格式
      // @ts-ignore
      lines[index][prop] = dayjs(formVal[key]).format('YYYY-MM-DD')
    } else {
      // @ts-ignore
      lines[index][prop] = formVal[key]
    }
  }
  return { lines: lines.filter(v => v) } as defs.UpdateQuotationCommand
}

const LineQuotationFormView = (props: {
  data: defs.QuotationLineDto
  title: string
  quotationCurrency?: defs.Currency
  lineQuotationMoneyChange?: (rfxId: string, money: number) => void
}) => {
  const { data = {}, title, quotationCurrency, lineQuotationMoneyChange } = props
  const isQuantity = data.orderMethod === bizEnums.PlaceOrderMethodEnum.QUANTITY
  const unTaxBenchmark = data.moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.UNTAXED
  // 计算单价、本行报价的依赖数据
  const [unitUnTaxMoney, setUnitUnTaxMoney] = useState<defs.MoneyDto>()
  const [quotationUnTaxMoney, setQuotationUnTaxMoney] = useState<defs.MoneyDto>()
  const [taxRate, setTaxRate] = useState<number>()
  // 计算单价、本行报价的依赖数据
  const [unitMoney, setUnitMoney] = useState<defs.MoneyDto>()
  const [quotationMoney, setQuotationMoney] = useState<defs.MoneyDto>()
  const [freight, setFreight] = useState<defs.MoneyDto>()

  useRCMount(() => {
    if (data) {
      setFreight(data.freight)
      setTaxRate(data.taxRate)
      setUnitUnTaxMoney(data.unitUnTaxMoney)
      setUnitMoney(data.unitMoney)
      setQuotationUnTaxMoney(data.quotationUnTaxMoney)
      setQuotationMoney(data.quotationMoney)
    }
  })

  const doChangeMoney = (v: typeof data) => {
    const draftData = { unitUnTaxMoney, unitMoney, quotationUnTaxMoney, quotationMoney, taxRate, freight, ...v }

    const { bidUnitUnTaxMoney, bidUnitMoney, bidUnTaxMoney, bidMoney } = computeBidMoney({
      bidUnitUnTaxMoney: draftData.unitUnTaxMoney,
      bidUnitMoney: draftData.unitMoney,
      bidUnTaxMoney: draftData.quotationUnTaxMoney,
      bidMoney: draftData.quotationMoney,
      taxRate: draftData.taxRate,
      // 原始数据
      quantity: data.quantity,
      moneyCalBenchmark: data.moneyCalBenchmark,
      orderMethod: data.orderMethod,
    })
    setFreight(draftData.freight)

    setTaxRate(draftData.taxRate)
    setUnitUnTaxMoney(bidUnitUnTaxMoney)
    setUnitMoney(bidUnitMoney)
    setQuotationUnTaxMoney(bidUnTaxMoney)
    setQuotationMoney(bidMoney)

    lineQuotationMoneyChange?.(
      data.rfxLineId,
      ((unTaxBenchmark ? bidUnTaxMoney?.amount : bidMoney?.amount) || 0) +
        (isQuantity ? draftData.freight?.amount || 0 : 0)
    )
  }

  return (
    <>
      <DataGrid>
        <DataFormItem style={{ display: 'none' }} name={`${title}-id`} label="id" required initialValue={data.id}>
          <Input />
        </DataFormItem>
        {isQuantity ? (
          <DataFormItem
            {...(unTaxBenchmark
              ? {
                  name: `${title}-unitUnTaxMoney`,
                  label: '单价（未税）',
                  initialValue: data.unitUnTaxMoney,
                }
              : {
                  name: `${title}-unitMoney`,
                  label: '单价（含税）',
                  initialValue: data.unitMoney,
                })}
            rules={[
              {
                required: true,
                message: '单价不可为0',
                validateTrigger: ['submit'],
                validator: (_: any, value: any) => {
                  if (!value || value.amount <= 0) {
                    return Promise.reject()
                  } else {
                    return Promise.resolve()
                  }
                },
              },
            ]}>
            <AmountInput
              currency={quotationCurrency!}
              onChange={v => doChangeMoney({ unitUnTaxMoney: v, unitMoney: v })}
            />
          </DataFormItem>
        ) : (
          <DataFormItem
            {...(unTaxBenchmark
              ? {
                  name: `${title}-quotationUnTaxMoney`,
                  label: '报价金额（未税）',
                  initialValue: data.quotationUnTaxMoney,
                }
              : {
                  name: `${title}-quotationMoney`,
                  label: '报价金额（含税）',
                  initialValue: data.quotationMoney,
                })}
            className={bem('fmi-nowrap')}
            rules={[
              {
                required: true,
                message: '报价金额不可为0',
                validateTrigger: ['submit'],
                validator: (_: any, value: any) => {
                  if (!value || value.amount <= 0) {
                    return Promise.reject()
                  } else {
                    return Promise.resolve()
                  }
                },
              },
            ]}>
            <AmountInput
              currency={quotationCurrency!}
              onChange={v => doChangeMoney({ quotationUnTaxMoney: v, quotationMoney: v })}
            />
          </DataFormItem>
        )}
        <DataFormItem name={`${title}-taxRate`} label="税率" required initialValue={data.taxRate}>
          <QuoteLaxSelect
            onChange={e => {
              setTaxRate(e as number)
              doChangeMoney({ taxRate: e as number })
            }}
          />
        </DataFormItem>
        {isQuantity && (
          <>
            {/* <DataFormItem
              name={`${title}-freight`}
              label="运费（含税）"
              required
              initialValue={data.freight || { currency: quotationCurrency, amount: 0 }}>
              <AmountInput currency={quotationCurrency!} onChange={v => doChangeMoney({ freight: v })} />
            </DataFormItem> */}
            <DataItem label="需求数量" value={data.quantity} />
            {unTaxBenchmark ? (
              <DataItem
                label={`单价 (含税)`}
                value={<DataMoney fontWeight="extraBold" size="default" value={unitMoney} />}
              />
            ) : (
              <DataItem
                label={`单价 (未税)`}
                value={<DataMoney fontWeight="extraBold" size="default" value={unitUnTaxMoney} />}
              />
            )}
          </>
        )}
        {isQuantity ? (
          <>
            <DataItem
              label={`报价金额\n(未税)`}
              value={<DataMoney fontWeight="extraBold" size="default" value={quotationUnTaxMoney} />}
            />
            <DataItem
              label={`报价金额\n(含税)`}
              value={<DataMoney fontWeight="extraBold" size="default" value={quotationMoney} />}
            />
          </>
        ) : unTaxBenchmark ? (
          <DataItem
            w100
            label={`报价金额\n(含税)`}
            value={<DataMoney fontWeight="extraBold" size="default" value={quotationMoney} />}
          />
        ) : (
          <DataItem
            w100
            label={`报价金额\n(未税)`}
            value={<DataMoney fontWeight="extraBold" size="default" value={quotationUnTaxMoney} />}
          />
        )}
        <DataFormItem name={`${title}-validityPeriod`} label="报价有效期至" required initialValue={data.validityPeriod}>
          <DatePicker />
        </DataFormItem>
        <DataFormItem
          name={`${title}-maintainPeriod`}
          label="维保期限 (月)"
          required
          initialValue={data.maintainPeriod}>
          <LicensingPeriodSelect />
        </DataFormItem>
        <DataFormItem
          name={`${title}-supplyCycle`}
          className={bem('fmi-nowrap')}
          label="供货周期 (自然日)"
          required
          initialValue={data.supplyCycle}>
          <InputNumber direction="vertical" min={0} toFixed={0} />
        </DataFormItem>
        <DataFormItem name={`${title}-invoiceType`} label="发票类型" required initialValue={data.invoiceType}>
          <Select options={bizEnums.InvoiceTypeEnum.values()} />
        </DataFormItem>
        <DataFormItem
          w100
          name={`${title}-quotationAttachments`}
          label="报价附件"
          initialValue={data.quotationAttachments}>
          <QutotationUpload
            fileSizeLimit={1024 * 1024 * 1024 * 1}
            tip={'单个文件小于1GB'}
            defaultValue={data.quotationAttachments || []}
          />
        </DataFormItem>
        <DataFormItem w100 name={`${title}-quotationRemark`} label="供应商报价备注" initialValue={data.quotationRemark}>
          <Input.TextArea showCount maxLength={1000} />
        </DataFormItem>
      </DataGrid>
    </>
  )
}

const LineQuotationView = (props: { data: defs.QuotationLineDto }) => {
  const { data } = props
  const isQuantity = data.orderMethod === bizEnums.PlaceOrderMethodEnum.QUANTITY
  const unTaxBenchmark = data.moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.UNTAXED

  return (
    <>
      <DataGrid>
        {isQuantity ? (
          <DataItem label={unTaxBenchmark ? '单价（未税）' : '单价（含税）'}>
            <DataMoney
              fontWeight="bold"
              size="xsmall"
              highlight={false}
              value={unTaxBenchmark ? data?.unitUnTaxMoney : data.unitMoney}
            />
          </DataItem>
        ) : (
          <DataItem label={unTaxBenchmark ? '报价金额 (未税)' : '报价金额（含税）'}>
            <DataMoney
              fontWeight="bold"
              size="xsmall"
              highlight={false}
              value={unTaxBenchmark ? data?.quotationUnTaxMoney : data.quotationMoney}
            />
          </DataItem>
        )}
        <DataItem label="税率" value={<DataTaxRate value={data.taxRate} />} />
        {isQuantity && (
          <>
            {/* <DataItem label="运费（含税）" value={data.freight?.amount} /> */}
            <DataItem label="需求数量" value={data.quantity} />
            {unTaxBenchmark ? (
              <DataItem
                label={`单价 (含税)`}
                value={<DataMoney fontWeight="extraBold" highlight value={data?.unitMoney} />}
              />
            ) : (
              <DataItem
                label={`单价 (未税)`}
                value={<DataMoney fontWeight="extraBold" highlight value={data?.unitUnTaxMoney} />}
              />
            )}
          </>
        )}
        {isQuantity ? (
          <>
            <DataItem
              label={`报价金额\n(未税)`}
              value={<DataMoney fontWeight="extraBold" highlight value={data?.quotationUnTaxMoney} />}
            />
            <DataItem
              label={`报价金额\n(含税)`}
              value={<DataMoney fontWeight="extraBold" highlight value={data?.quotationMoney} />}
            />
          </>
        ) : unTaxBenchmark ? (
          <DataItem
            w100
            label={`报价金额\n(含税)`}
            value={<DataMoney fontWeight="extraBold" highlight value={data?.quotationMoney} />}
          />
        ) : (
          <DataItem
            w100
            label={`报价金额\n(未税)`}
            value={<DataMoney fontWeight="extraBold" highlight value={data?.quotationUnTaxMoney} />}
          />
        )}
        <DataItem label="报价有效期至" value={formatDateTime(data.validityPeriod, 'YYYY-MM-DD')} />
        <DataItem label="维保期限 (月)" value={renderPeriod(data.maintainPeriod)} />
        <DataItem label="供货周期 (自然日)" value={data.supplyCycle} />
        <DataItem label="发票类型" value={data.invoiceType && bizEnums.InvoiceTypeEnum.pick(data.invoiceType)?.name} />
        <DataItem
          w100
          label="报价附件"
          value={<QutotationAttachments dataId={''} attachments={data.quotationAttachments} />}
        />
        <DataItem w100 label="供应商报价备注" value={data.quotationRemark} />
      </DataGrid>
    </>
  )
}

const LineTitleView = (props: { title: string; status?: defs.QuotationLineStatus }) => {
  const { title, status } = props
  return (
    <div className={bem('ltv')}>
      <div>{title}</div>
      {status === bizEnums.QuotationLineStatusEnum.HAS_WINNING_BID && (
        <div className={bem('ltv-award')}>
          <GoldMedalsFilled size={14} />
          中标
        </div>
      )}
    </div>
  )
}

const LineView = (props: {
  quotationCurrency?: defs.Currency
  status: defs.QuotationStatus
  title: string | ReactNode
  quotable?: boolean
  rawTitle?: string
  data: defs.QuotationLineDto
  isEdit?: boolean
  onLineGiveUp: (lineId: string) => void
  lineQuotationMoneyChange?: (rfxId: string, money: number) => void
}) => {
  const {
    status,
    data,
    title,
    rawTitle: propRawTitle,
    isEdit = false,
    quotationCurrency,
    quotable,
    onLineGiveUp,
    lineQuotationMoneyChange,
  } = props

  const rawTitle = propRawTitle || (typeof title === 'string' ? title : '')

  const extra = () => {
    return status === bizEnums.QuotationStatusEnum.NOT_BID ||
      data.isHistoryTenderDiscarded ||
      !(status === bizEnums.QuotationStatusEnum.WAIT_QUOTATION || isEdit) ? null : data.isTenderDiscarded ? (
      <Tooltip title={'恢复报价'}>
        <Button
          type="checked"
          icon={<RollTopLeft size={16} />}
          onClick={async () => {
            onLineGiveUp(data.id!)
          }}
        />
      </Tooltip>
    ) : (
      <Popconfirm
        placement="top"
        content={
          <div>
            确定要放弃【
            <span style={{ fontWeight: 'bold' }}>{data.goodsName}</span>
            】的报价？
          </div>
        }
        onOk={async () => {
          onLineGiveUp(data.id!)
        }}>
        <Tooltip title={'放弃报价'}>
          <Button type="checked" danger icon={<Delete size={16} />} />
        </Tooltip>
      </Popconfirm>
    )
  }

  return (
    <DataCard
      id={data.id}
      collapsable
      className={bem('bls', { discarded: !!data.isTenderDiscarded })}
      title={
        <div className={bem('bls-title')}>
          {title}
          {!!data.isTenderDiscarded && <Tag size="small" text="放弃报价" color="light-grey" />}
        </div>
      }
      action={extra()}>
      <DataBlock title="需求信息">
        <DataGrid>
          <DataItem w100 label="商品名称" value={<span className={bem('goodsName')}>{data.goodsName}</span>} />
          <DataItem label="品牌" value={data.goodsBrand} />
          <DataItem label="配置" value={data.goodsConfiguration} />
          <DataItem label="授权期限 (月)" value={renderPeriod(data.licensingPeriod)} />
          <DataItem
            label="收货地址"
            value={[data?.countryName, data?.stateName, data?.cityName, data?.address].filter(item => !!item).join('-')}
          />
          <DataItem
            w100
            label="询价附件"
            value={<QutotationAttachments dataId={''} attachments={data.attachments} />}
          />
          <DataItem w100 label="备注" value={data.remark} />
        </DataGrid>
      </DataBlock>
      {!data.isTenderDiscarded && (
        <DataBlock title="报价信息">
          {status === bizEnums.QuotationStatusEnum.NOT_BID ? (
            <QuotationLineEmpty />
          ) : status === bizEnums.QuotationStatusEnum.WAIT_QUOTATION || isEdit ? (
            <LineQuotationFormView
              data={data}
              title={rawTitle}
              quotationCurrency={quotationCurrency}
              lineQuotationMoneyChange={lineQuotationMoneyChange}
            />
          ) : (
            <LineQuotationView data={data} />
          )}
        </DataBlock>
      )}
    </DataCard>
  )
}

export default (params: {
  data: defs.QuotationDetailDto
  id: string
  mutate: (v: Partial<apiService.QuotationDetailDto> | undefined) => void
}) => {
  // const { query } = useRoute()
  const [fm] = Form.useForm()
  const { push } = useRouter()
  const { data, id, mutate } = params
  // const { data, loading, request } = useManualService(apiService.quotationQuotationDetail)
  const { getWorkbenchStatistics } = useDispatch(dispatch => dispatch.workbenchStatistics)
  const [isBefore, setIsBefore] = useState(false)
  const [changeQuote, setChangeQuote] = useState(false)
  const { totalMoney, lineQuotationMoneyChange } = useTotalPrice(data)
  // 和梦杰确认过，取行上的价格基准，都一样的，可以优化到，放在报价头上
  const unTaxBenchmark = data?.lines![0].moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.UNTAXED
  // useRCMount(() => query.id && request({ id: query.id }))

  useEffect(() => {
    if (data?.deadlineAt && dayjs().isBefore(data?.deadlineAt)) {
      setIsBefore(true)
    }
  }, [data])

  const doGiveUp = async () => {
    Modal.confirm({
      title: '您即将弃标，一旦提交，将被视为正式且不可更改。您确定要继续提交吗？',
      confirmLoading: true,
      onOk: async () => {
        try {
          await apiService.quotationQuotationGiveUpQuotation({ quotationId: id })
          Message.success('弃标成功')
          getWorkbenchStatistics()
          push('/quotation')
        } catch (error) {
          console.log(error)
        }
      },
    })
  }

  const genParams = () => {
    const formVal = fm.getFieldsValue()

    const formParams: defs.UpdateQuotationCommand = genSubmitParams(formVal)
    // 放在外面，不放在genSubmitParams能解决，放弃行，导致的没有formVal缺失line的问题，所以循环data.lines
    const genLines: defs.UpdateQuotationLineCommand[] = (data.lines || []).map(v => {
      // 这里是构建，没有弃标的数据
      const genFormLine = {
        ...(formParams.lines || []).filter(fpLine => v.id === fpLine.id)[0],
        isTenderDiscarded: false,
      }
      return v.isTenderDiscarded ? { id: v.id, isTenderDiscarded: v.isTenderDiscarded } : genFormLine
    })
    const params: defs.UpdateQuotationCommand = { lines: genLines }
    // 金额基准值再传回去
    for (const v of params.lines || []) {
      v.moneyCalBenchmark = data.moneyCalBenchmark
    }
    return params
  }

  const doSubmit = async () => {
    try {
      await fm.validateFields().catch(e => {
        fm.scrollToField(e.errorFields[0].name, { behavior: 'smooth', scrollMode: 'if-needed', block: 'center' })
        throw e
      })
      const params: defs.UpdateQuotationCommand = genParams()
      if ((params.lines || []).every(v => v.isTenderDiscarded)) {
        Modal.confirm({
          title: '无法提交',
          content: '至少提交 1行报价',
          okText: '我知道了',
          okCancel: false,
        })
        return
      }

      Modal.confirm({
        title: '您即将提交报价，请确认所有信息无误。您确定要继续提交吗？',
        confirmLoading: true,
        onOk: async () => {
          try {
            await apiService.quotationQuotationUpdateQuotation({ quotationId: id }, params)
            Message.success('提交成功')
            getWorkbenchStatistics()
            push('/quotation')
          } catch (error) {
            console.log(error)
          }
        },
      })
    } catch (error) {
      Message.warn('请检查必填项是否填写')
      console.log(error)
    }
  }

  const doSave = async () => {
    const params: defs.UpdateQuotationCommand = genParams()

    apiService.stagingStagingSaveQuotation(
      { quotationId: id },
      { quotationId: id, stagingInfo: JSON.stringify(params) }
    )
    Message.success('已保存至协同平台，完成填写需提交报价')
  }

  const hasQuotationAction = () =>
    !isBefore ? null : changeQuote ? (
      <>
        <Button icon={<Close />} type="checked-neutral" onClick={() => window.close()}>
          取消
        </Button>
        <AsyncButton disabled={!isBefore} icon={<Bill />} type="primary" onClick={doSubmit}>
          报价
        </AsyncButton>
      </>
    ) : (
      <Button disabled={!isBefore} icon={<Edit />} type="primary" onClick={() => setChangeQuote(true)}>
        报价变更
      </Button>
    )

  const onLineGiveUp = (id: string) => {
    let genData = {
      ...data,
      lines: data.lines?.map(v => (v.id !== id ? v : { ...v, isTenderDiscarded: !v.isTenderDiscarded })),
    }
    let changeDataIndex = 0
    const changeData = genData.lines?.find((v, index) => {
      if (v.id === id) {
        changeDataIndex = index
        return true
      }
      return false
    })
    const isQuantity = changeData?.orderMethod === bizEnums.PlaceOrderMethodEnum.QUANTITY
    const unTaxBenchmark = changeData?.moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.UNTAXED
    let changeDataMoney = 0
    if (changeData && !changeData?.isTenderDiscarded) {
      const { bidUnTaxMoney, bidMoney } = computeBidMoney({
        bidUnitUnTaxMoney: changeData?.unitUnTaxMoney,
        bidUnitMoney: changeData?.unitMoney,
        bidUnTaxMoney: changeData?.quotationUnTaxMoney,
        bidMoney: changeData?.quotationMoney,
        taxRate: changeData?.taxRate,
        // 原始数据
        quantity: changeData.quantity,
        moneyCalBenchmark: changeData.moneyCalBenchmark,
        orderMethod: changeData.orderMethod,
      })
      changeDataMoney =
        ((unTaxBenchmark ? bidUnTaxMoney?.amount : bidMoney?.amount) || 0) +
        (isQuantity ? changeData?.freight?.amount || 0 : 0)
    } else {
      // 撤回操作时，如果编辑过金额，再去撤回，formItem的value会有缓存，所以这里要重新赋值，避免实际value与input框不一致
      const prevKey = `Line${changeDataIndex + 1}`
      changeData &&
        fm.resetFields([
          `${prevKey}-taxRate`,
          `${prevKey}-unitUnTaxMoney`,
          `${prevKey}-unitMoney`,
          `${prevKey}-freight`,
          `${prevKey}-quotationUnTaxMoney`,
          `${prevKey}-quotationMoney`,
          `${prevKey}-validityPeriod`,
          `${prevKey}-maintainPeriod`,
          `${prevKey}-supplyCycle`,
          `${prevKey}-invoiceType`,
          `${prevKey}-quotationAttachments`,
          `${prevKey}-quotationRemark`,
        ])
    }
    changeData?.rfxLineId && lineQuotationMoneyChange?.(changeData?.rfxLineId, changeDataMoney)
    mutate(genData)
  }

  const action = () =>
    data?.status === bizEnums.QuotationStatusEnum.WAIT_QUOTATION ? (
      <>
        <Button icon={<Close />} type="checked-neutral" onClick={() => window.close()}>
          取消
        </Button>
        <AsyncButton disabled={!isBefore} icon={<Save />} type="checked-neutral" onClick={doSave}>
          保存
        </AsyncButton>
        <Button disabled={!isBefore} icon={<Delete />} danger onClick={doGiveUp}>
          弃标
        </Button>
        <AsyncButton disabled={!isBefore} icon={<Bill />} type="primary" onClick={doSubmit}>
          报价
        </AsyncButton>
      </>
    ) : data?.status === bizEnums.QuotationStatusEnum.HAS_QUOTATION ? (
      hasQuotationAction()
    ) : null

  // if (loading) return <PageLoading />
  return (
    <PageContainer className={bem()}>
      <PageHeader
        title={`报价单详情`}
        action={
          <>
            <div className={bem('header-tm')}>
              <div>{unTaxBenchmark ? '报价总额（未税含运费）' : '报价总额（含税含运费）'}</div>
              <DataMoney fontWeight="extraBold" size="default" value={totalMoney} />
            </div>
            {action()}
          </>
        }
      />
      <DataForm form={fm}>
        <MainContainer className={bem('main')}>
          <DataContainer
            anchor={(data?.lines || []).map((item, index) => ({
              id: item.id!,
              element: <LineAnchorTitleView data={item} title={`L${index + 1} : `} />,
            }))}
            scrollOffsetTop={156}>
            <DataCard className={bem('fc')}>
              <QutotationStatusView data={data!} onEnd={() => setIsBefore(false)} />
              <DataGrid>
                {/* <DataItem label="标题" value={data?.title} /> */}
                <DataItem w100 label="询价单号" value={data?.rfxId} />
                <DataItem
                  w100
                  label="询价公司"
                  value={
                    <CompanyView
                      companyName={data?.settlementCompanyName}
                      companyCode={data?.settlementCompanyCountryCode}
                    />
                  }
                />
                <DataItem
                  w100
                  label={data?.isClarification ? '报价澄清截止时间' : '报价截止时间'}
                  value={<ShowCutOffTime targetTime={data?.deadlineAt} value={24} />}
                />
                <DataItem
                  label="支付方式"
                  value={
                    data?.paymentMethod !== undefined && bizEnums.PaymentMethodEnum.pick(data?.paymentMethod)?.name
                  }
                />
                <DataItem label="账期" value={data?.accountPeriod !== undefined ? `${data?.accountPeriod}天` : '-'} />
                <DataItem w100 label="询价单备注" value={data?.rfxRemark} />
                <DataItem
                  w100
                  label="询价单附件"
                  value={<QutotationAttachments dataId={''} attachments={data?.attachments} />}
                />
              </DataGrid>
            </DataCard>
            {(data?.lines || [{}])?.map((item, index) => (
              <LineView
                isEdit={changeQuote}
                onLineGiveUp={onLineGiveUp}
                title={<LineTitleView title={`Line${index + 1}`} status={item.status} />}
                rawTitle={`Line${index + 1}`}
                key={item.id}
                status={data?.status!}
                quotable={data?.quotable}
                quotationCurrency={data?.quotationCurrency}
                // 服务端告知，采用头部的currency，全报价单都一样的币种，这里直接覆盖下
                data={item}
                lineQuotationMoneyChange={lineQuotationMoneyChange}
              />
            ))}
            {/* TODO: 普通的报价模式，增加澄清的变更记录，有接口定义还需要调整再看下 */}
            {!!data?.isClarification && <HistoryLog list={data?.quotationHistories} id={id} />}
          </DataContainer>
        </MainContainer>
      </DataForm>
    </PageContainer>
  )
}
