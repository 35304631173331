import { BEMGenerator, useRCComputed } from '@oahz/neact-utils'
import { useRoute, useService } from '@oahz/neact'
import { Message } from '@bedrock/components'
import I18N from '@feature/i18n'
import { MainContainer, PageContainer, PageLoading } from '@/components/page-view'
import { DataBlock, DataFormItem, DataPanel } from '@/components/data-view'
import {
  SupplierBlockTitle,
  SupplierContentFormItems,
  SupplierForm,
  SupplierFormActions,
  SupplierHeader,
  SupplierTextArea,
  convertDetailDtoToFormFieldsValue,
  convertFormFieldsValueToSubmitCommand,
  isChinaEnterprise,
  isChinaPerson,
  isOverseaEnterprise,
  isOverseaPerson,
  pickHeaderDataFromDetailDto,
  useSupplierForm,
} from '@/components/supplier'
import { useDispatch, useSelector } from '@/store'
import { apiService, bizService } from '@/services'
import './style.less'

const bem = BEMGenerator('peinup')

export default () => {
  const { query } = useRoute()
  const { supplierInfo } = useSelector(state => state.account)
  const { refreshSupplierInfo } = useDispatch(dispatch => dispatch.account)
  const { data, loading } = useService(async () => {
    // 信息变更页如果是最新一次信息变更（且是不通过的），那可以从此次变更填写的内容，再次发起信息变更
    if (query.applicationId) {
      const res = await bizService.supplierApplicationQuerySpAppModDetail({
        subSet: supplierInfo.subSet,
        applicationId: +query.applicationId,
      })
      return { ...res, ...res?.current }
    }
    // 正常，则从供应商详情的基础上发起信息变更
    return bizService.supplierQuerySupplierDetail({ subSet: supplierInfo.subSet })
  })
  const { fm, canSubmit, onValuesChange, replacePage } = useSupplierForm()

  const doSubmit = async () => {
    if (!data) return

    await fm.validateFields()
    const fmData = fm.getFieldsValue()

    const mdata = {
      id: supplierInfo.id,
      // mdmId再传回去
      mdmId: data.mdmId,
      // cooperateStatus再传回去
      cooperateStatus: data.cooperateStatus,
      ...convertFormFieldsValueToSubmitCommand(fmData),
    }

    if (isChinaEnterprise(data)) {
      await apiService.identityEnterpriseBoardedModifyChinaEnterprise(mdata)
    } else if (isChinaPerson(data)) {
      await apiService.identityEnterpriseBoardedModifyChinaPerson(mdata)
    } else if (isOverseaEnterprise(data)) {
      await apiService.identityEnterpriseBoardedModifyOverseaEnterprise(mdata)
    } else if (isOverseaPerson(data)) {
      await apiService.identityEnterpriseBoardedModifyOverseaPerson(mdata)
    }

    // 刷新最新的供应商基础信息，如其applicationId发送的变化
    await refreshSupplierInfo()

    Message.success(I18N.auto.tiJiaoChengGong)
    // 重新进入供应商主页
    replacePage(`/enterprise/info-changes`)
  }

  const initialValue = useRCComputed(() => {
    return data ? convertDetailDtoToFormFieldsValue(data) : {}
  }, [data])

  const headerData = useRCComputed(() => (data ? pickHeaderDataFromDetailDto(data) : {}), [data])

  if (!data) return loading ? <PageLoading /> : null

  return (
    <PageContainer className={bem()} htmlTitle={data?.id + ''}>
      <SupplierHeader
        data={headerData}
        action={<SupplierFormActions fm={fm} onSubmit={doSubmit} submitDisabled={!canSubmit} />}
      />

      <MainContainer w1280 className={bem('main')}>
        <SupplierForm form={fm} initialValues={initialValue} onValuesChange={onValuesChange}>
          <ChangeInfo />
          <SupplierContentFormItems fm={fm} defaultValue={data} />
        </SupplierForm>
      </MainContainer>
    </PageContainer>
  )
}

const ChangeInfo = () => {
  return (
    <DataPanel>
      <DataBlock title={<SupplierBlockTitle title={I18N.auto.bianGengXinXi} required />}>
        <DataFormItem w100 label={I18N.auto.bianGengShuoMing} name="remark" required>
          <SupplierTextArea />
        </DataFormItem>
      </DataBlock>
    </DataPanel>
  )
}
